<template>
  <div v-if="tableName.includes('use conformance')" id='table_wrapper' class='sl-ml-10px'>
    <br><br><br><br><br>
    <div>
      <span><b>Example Use Permitted:</b></span><br>
      {{ dataItems['Property Use'] }} Use [IS/IS NOT] Permitted by Right within {{ dataItems['Zoning Dist Abbrev #1'] }}the District.<br>
      Code Section Referenced: [SECTION CODE]
    </div>
    <br>
    <div>
      <div><span>Property Use</span><span>{{ dataItems['Property Use'] }}</span></div>
      <div><span>Proposed Property Use</span><span>{{ dataItems['Proposed Property Use'] }}</span></div>
      <div><span><b>Zoning Dist Abbrev #1</b></span><span>{{ dataItems['Zoning Dist Abbrev #1'] }}</span></div>
      <div v-if="dataItems['Zoning Dist Abbrev #2']"><span><b>Zoning Dist Abbrev #2</b></span><span>{{ dataItems['Zoning Dist Abbrev #2'] }}</span></div>
      <div v-if="dataItems['Zoning Dist Abbrev #3']"><span><b>Zoning Dist Abbrev #3</b></span><span>{{ dataItems['Zoning Dist Abbrev #3'] }}</span></div>
      <div v-if="dataItems['Zoning Dist Abbrev #4']"><span><b>Zoning Dist Abbrev #4</b></span><span>{{ dataItems['Zoning Dist Abbrev #4'] }}</span></div>
    </div>
  </div>

  <div v-else-if='showExecutiveSummary'
       id='table_wrapper' class='sl-ml-10px sl-min-w-220px'>
    <br><br><br><br><br>
    <div class='sl-mr-20px'>
      <div>
        <span>Parcel Size (Acres)</span>
        <span>{{ this.numberWithDelimiter(dataItems['Parcel Size (Acres)']) }}</span>
      </div>
      <div>
        <span>Parcel Size (SF)</span>
        <span>{{ this.numberWithDelimiter(dataItems['Parcel Size (SF)']) }}</span>
      </div>
      <div>
        <span>Number of Buildings</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Buildings']) }}</span>
      </div>
      <div>
        <span>Number of Stories</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Stories']) }}</span>
      </div>
      <div>
        <span>Year Built</span>
        <span>{{ dataItems['Year Built'] }}</span>
      </div>
      <div>
        <span>Number of Tenant Spaces</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Tenant Spaces']) }}</span>
      </div>
      <div>
        <span>Number of Beds</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Beds']) }}</span>
      </div>
      <div>
        <span>Number of Storage Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Storage Units']) }}</span>
      </div>
      <div>
        <span>Number of Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Units']) }}</span>
      </div>
      <div>
        <span>Number of Mobile Home Pads</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Mobile Home Pads']) }}</span>
      </div>
      <div>
        <span>Number of Guest Rooms</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Guest Rooms']) }}</span>
      </div>
      <div>
        <span>Number of Residential Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Residential Units']) }}</span>
      </div>
      <div>
        <span>Number of Studio Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Studio Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Number of One Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of One Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Number of Two Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Two Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Number of Three Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Three Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Number of Four Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Four Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Number of Five Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Five Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Number of Six Bedroom Units</span>
        <span>{{ this.numberWithDelimiter(dataItems['Number of Six Bedroom Units']) }}</span>
      </div>
      <div>
        <span>Building Footprint (SF)</span>
        <span>{{ this.numberWithDelimiter(dataItems['Building Footprint (SF)']) }}</span>
      </div>
      <div>
        <span>Gross Building Area (SF)</span>
        <span>{{ this.numberWithDelimiter(dataItems['Gross Building Area (SF)']) }}</span>
      </div>
      <div>
        <span>Net Rentable Area (SF)</span>
        <span>{{ this.numberWithDelimiter(dataItems['Net Rentable Area (SF)']) }}</span>
      </div>
      <div>
        <span>Abutting Zoning Designations</span>
        <span>{{ dataItems['Abutting Zoning Designations'] }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  props: {
    tableName: String,
    dataItems: Object,
  },

  methods: {
    showExecutiveSummary() {
      return this.tableName.includes('bulk requirements')
             || this.tableName.includes('optional requirements')
             || this.tableName.includes('parking requirements')
             || this.tableName.includes('proposed parking requirements');
    },

    numberWithDelimiter(number) {
      if (!number) return '';
      return Intl.NumberFormat().format(number);
    },
  },
};
</script>
